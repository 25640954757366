import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  button: {
    marginTop: 12,
  },
});
