import React from "react";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

interface UserErrorProps {
  onClose: () => void;
}

export const UserError: React.FC<UserErrorProps> = ({ onClose }) => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  return (
    <Dialog
      open
      title="Inquiry response"
      onClose={onClose}
      tier={facility.subscriptionTier}
    >
      <div css={css.container}>
        <Typography bolded color="error" align="center" gap>
          Login not authorized
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          fullWidth
          fab
          size="large"
          onClick={onClose}
          data-test="close-button"
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};
