import React from "react";
import { DateTime } from "luxon";
import keyBy from "lodash/keyBy";
import { useHistory, useLocation } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";
import { trancateName } from "@helpers/trancateName";
import { gendersMap } from "@constants/genders";
import { ServiceInquryPreferedEnrollmentSchedule } from "@constants/service-inquiry";

interface FamilyProfileDialogProps {
  inquiry: Models.FacilityContactRequest;
}

export const FamilyProfileDialog: React.FC<FamilyProfileDialogProps> = ({
  inquiry,
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  const handleClose = () => {
    history.replace({
      hash: "",
      search,
    });
  };

  return (
    <Dialog
      open
      title="Family profile"
      onClose={handleClose}
      tier={facility.subscriptionTier}
    >
      <div css={css.container}>
        <Typography gutterBottom>
          <Typography variant="inherit" bolded>
            Parent name:
          </Typography>{" "}
          {trancateName(inquiry.parentFirstName, inquiry.parentLastName)}
        </Typography>
        <Typography gutterBottom>
          <Typography variant="inherit" bolded>
            Child's birthday:
          </Typography>{" "}
          {DateTime.fromISO(inquiry.childBirthday).toFormat("MM/dd/yyyy")}
        </Typography>
        <Typography gap>
          <Typography variant="inherit" bolded>
            Gender:
          </Typography>{" "}
          {gendersMap[inquiry.childGender].shortTitle}
        </Typography>
        <Typography gutterBottom bolded>
          Target enrollment:
        </Typography>
        <Typography paragraph>
          {DateTime.fromISO(inquiry.programEnrollmentDay).toFormat(
            "MMMM, yyyy",
          )}
        </Typography>
        <Typography gutterBottom bolded>
          Preferred enrollment schedule:
        </Typography>
        <Typography gap>
          {!!inquiry.programEnrollmentSchedule
            ? keyBy(ServiceInquryPreferedEnrollmentSchedule, "id")[
                inquiry.programEnrollmentSchedule
              ].name
            : "undecided"}
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          fullWidth
          size="large"
          onClick={handleClose}
          data-test="close-button"
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};
