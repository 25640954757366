import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useHistory, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import qs from "query-string";
import Checkbox from "@material-ui/core/Checkbox";
import track, { useTracking } from "react-tracking";

import Typography from "@ui-kit/Typography";
import Dialog from "@ui-kit/Dialog";
import { OutlinedInput, Input } from "@ui-kit/InputFields";
import { createCss } from "./styles";
import SuccessStep from "@components/SuccessStep";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { required } from "@validators";
import { ROUTES } from "@constants";
import { Models, FACILITY_API } from "@services/api";
import { getFacilityFullAddress, isFacilityOwner } from "@selectors";
import { SignInWarning } from "./SignInWarning";
import { UserError } from "./UserError";
import { useEnrollmentResponseStore } from "@store/EnrollmentResponseStore";
import { Spacer } from "@ui-kit/Spacer";
import { QuitDialog } from "@components/QuitDialog";
import { trancateName } from "@helpers/trancateName";
import { trimEventValue } from "@helpers/trimEventValue";
import { ShadowDivider } from "@components/ShadowDivider";
import { FamilyProfileDialog } from "./FamilyProfile";
import { must } from "@utils/must";
import { useNotificationStore } from "@store/NotificationStore";
import { useQueryClient } from "react-query";
import { QUERIES } from "@constants/queries";

type FormFields = {
  response: string;

  mediaWebsitePhotosUrl: string;
  mediaGooglePhotosUrl: string;
  mediaFacebookPhotosUrl: string;
  vrTourUrl: string;
  videoTourUrl: string;
  visitsUrl: string;
};

type Params = {
  inquiryID: string;
};

type ToAttach =
  | "mediaGooglePhotosUrl"
  | "mediaWebsitePhotosUrl"
  | "mediaFacebookPhotosUrl"
  | "vrTourUrl"
  | "videoTourUrl"
  | "visitsUrl";

const ServiceInquiryResponse: React.FC = () => {
  const { trackEvent } = useTracking();
  const { inquiryID } = useParams<Params>();
  const { search, hash } = useLocation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [{ user }] = useUserStore();
  const [, { setUnknownErrorNotification }] = useNotificationStore();
  const [showWarning, setShowWarning] = useState<
    "full" | "administrator" | false
  >(false);
  const [showUserError, setShowUserError] = useState(false);
  const [{ facility }, { saveFacility }] = useDeepDivePanelStore();
  const [inquiry, setInquiry] = useState<Models.FacilityContactRequest | null>(
    null,
  );
  const { handleSubmit, control, errors, watch, formState, trigger, setError } =
    useForm<FormFields>();
  const [step, setStep] = useState(1);
  const [quit, setQuit] = useState(false);
  const [toAttach, setToAttach] = useState<ToAttach[]>(() => {
    return [
      facility.toursInfo.videoTourUrl ? "videoTourUrl" : false,
      facility.toursInfo.vrTourUrl ? "vrTourUrl" : false,
      facility.mediaWebsitePhotosUrl ? "mediaWebsitePhotosUrl" : false,
      facility.mediaFacebookPhotosUrl ? "mediaFacebookPhotosUrl" : false,
      facility.mediaGooglePhotosUrl ? "mediaGooglePhotosUrl" : false,
      facility.toursInfo.visitsUrl ? "visitsUrl" : false,
    ].filter(Boolean) as ToAttach[];
  });
  const [{ responseText }, { setAfterClaimCallback, resetAfterClaimCallback }] =
    useEnrollmentResponseStore();
  const css = createCss();

  const initFetch = useCallback(async () => {
    const { data } = await FACILITY_API.facilityContactRequestsById({
      id: parseInt(inquiryID, 10),
    });
    setInquiry(data);
    trackEvent({
      action: "Page Loaded",
      inquiryId: inquiryID,
    });
  }, [setInquiry]);

  useEffect(() => {
    void initFetch();
  }, [initFetch]);

  const checkAuthorization = () => {
    if (!user) {
      history.replace({
        pathname: ROUTES.LOGIN_FORM,
        search: qs.stringify({
          addressCountry: facility.address.country,
        }),
      });
    } else {
      void sendRequest();
    }
  };

  const onClose = () => {
    trackEvent({
      action: "Close Page",
      inquiryId: inquiryID,
    });
    resetAfterClaimCallback();
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  };

  const handleToAttachClick = (link: ToAttach) => {
    const copy = toAttach.slice();
    const idx = copy.findIndex((a) => a === link);

    if (idx !== -1) {
      copy.splice(idx, 1);
    } else {
      copy.push(link);
    }

    setToAttach(copy);
  };

  if (!inquiry) {
    return null;
  }

  const renderLinkField = (field: {
    name: ToAttach;
    label: string;
    defaultValue: string;
    required: boolean;
  }) => (
    <div css={css.linkRow} key={field.name}>
      <Checkbox
        checked={toAttach.includes(field.name)}
        onClick={() => {
          if (!toAttach.includes(field.name)) {
            if (!watch(field.name)) {
              setError(field.name, {
                message: required().required,
                type: "manual",
              });
              return;
            }
            void trigger(field.name).then((isValid) => {
              if (isValid) {
                handleToAttachClick(field.name);
              }
            });
          } else {
            handleToAttachClick(field.name);
          }
        }}
        className="checkbox"
        color="primary"
      />
      <Controller
        render={({ onChange, ...rest }) => (
          <Input
            {...rest}
            fullWidth
            error={errors[field.name]?.message}
            data-test={`${field.name}-field`}
            label={field.label}
            onChange={(event) => {
              const { value } = event.target;

              if (!value) {
                setToAttach((prev) => prev.filter((a) => a !== field.name));
              } else {
                if (!toAttach.includes(field.name)) {
                  const copy = toAttach.slice();
                  copy.push(field.name);

                  setToAttach(copy);
                }
              }

              onChange(trimEventValue(event));
            }}
            required={field.required}
          />
        )}
        rules={{
          ...(field.required ? required() : {}),
        }}
        name={field.name}
        defaultValue={field.defaultValue}
        control={control}
      />
    </div>
  );

  const getToShareLinks = () => {
    const result = [];

    if (!facility.toursInfo.videoTourUrl) {
      result.push({
        defaultValue: facility.toursInfo.videoTourUrl || "",
        label: "Virtual school tour: paste link",
        name: "videoTourUrl" as ToAttach,
        required: false,
      });
    }
    if (!facility.toursInfo.vrTourUrl) {
      result.push({
        defaultValue: facility.toursInfo.vrTourUrl || "",
        label: "360-degree tour: paste link",
        name: "vrTourUrl" as ToAttach,
        required: false,
      });
    }
    if (!facility.mediaFacebookPhotosUrl) {
      result.push({
        defaultValue: facility.mediaFacebookPhotosUrl || "",
        label: "Facebook page: paste link",
        name: "mediaFacebookPhotosUrl" as ToAttach,
        required: false,
      });
    }
    if (!facility.mediaWebsitePhotosUrl) {
      result.push({
        defaultValue: facility.mediaWebsitePhotosUrl || "",
        label: "School website: paste link",
        name: "mediaWebsitePhotosUrl" as ToAttach,
        required: !facility.mediaWebsitePhotosUrl,
      });
    }
    if (!facility.mediaGooglePhotosUrl) {
      result.push({
        defaultValue: facility.mediaGooglePhotosUrl || "",
        label: "Google photos: paste link",
        name: "mediaGooglePhotosUrl" as ToAttach,
        required: false,
      });
    }
    if (!facility.toursInfo.visitsUrl) {
      result.push({
        defaultValue: facility.toursInfo.visitsUrl || "",
        label: "Appointment calendar: paste link",
        name: "visitsUrl" as ToAttach,
        required: false,
      });
    }
    return result;
  };

  const renderOwnerFields = () => {
    const links = getToShareLinks();

    if (!links.length || !isFacilityOwner(facility, user)) {
      return null;
    }

    return (
      <Fragment>
        <Spacer size="small" />
        <Typography align="center" bolded variant="h4">
          Share links with parents
        </Typography>
        <Spacer size="small" />

        <div>{links.map(renderLinkField)}</div>
      </Fragment>
    );
  };

  const sendRequest = handleSubmit(
    async ({
      response,
      mediaGooglePhotosUrl,
      mediaWebsitePhotosUrl,
      mediaFacebookPhotosUrl,
      videoTourUrl,
      vrTourUrl,
      visitsUrl,
    }) => {
      try {
        const facilityCopy = cloneDeep(facility);

        if (
          toAttach.includes("mediaFacebookPhotosUrl") &&
          mediaFacebookPhotosUrl
        ) {
          facilityCopy.mediaFacebookPhotosUrl = mediaFacebookPhotosUrl;
        }

        if (toAttach.includes("mediaGooglePhotosUrl") && mediaGooglePhotosUrl) {
          facilityCopy.mediaGooglePhotosUrl = mediaGooglePhotosUrl;
        }

        if (
          toAttach.includes("mediaWebsitePhotosUrl") &&
          mediaWebsitePhotosUrl
        ) {
          facilityCopy.mediaWebsitePhotosUrl = mediaWebsitePhotosUrl;
        }

        if (toAttach.includes("vrTourUrl") && vrTourUrl) {
          facilityCopy.toursInfo.vrTourUrl = vrTourUrl;
        }

        if (toAttach.includes("videoTourUrl") && videoTourUrl) {
          facilityCopy.toursInfo.videoTourUrl = videoTourUrl;
        }

        if (toAttach.includes("visitsUrl") && visitsUrl) {
          facilityCopy.toursInfo.visitsUrl = visitsUrl;
        }

        if (isFacilityOwner(facility, user) && toAttach.length) {
          await saveFacility(facilityCopy);
        }

        const text = response;
        const inquiryId = must(inquiry.id);

        if (
          !isFacilityOwner(facility, user) ||
          facility.profileCompleteness.percent === 100
        ) {
          await FACILITY_API.facilityContactRequestsByIdRespond({
            facilityContactRequestResponse: {
              text,
            },
            id: inquiryId,
          });

          trackEvent({
            action: "Submitted Successfully",
            inquiryId,
          });
          void queryClient.invalidateQueries(QUERIES.USER_CHATS);
          setStep(2);
        } else {
          setAfterClaimCallback(
            async () => {
              await FACILITY_API.facilityContactRequestsByIdRespond({
                facilityContactRequestResponse: {
                  text,
                },
                id: inquiryId,
              });

              trackEvent({
                action: "Submitted Successfully",
                inquiryId,
              });
              void queryClient.invalidateQueries(QUERIES.USER_CHATS);
            },
            inquiryId,
            text,
          );

          history.push(`/map/${facility.id}/missing-data`);
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          if (!!facility.accountId) {
            setShowUserError(true);
          } else {
            setShowWarning("administrator");
          }
        } else {
          const errors = error?.response?.data?.errors;
          let handled = false;

          if (errors) {
            for (const e of errors) {
              const chunks: string[] = e.source.pointer.split("/");

              chunks.forEach((c) => {
                if (toAttach.includes(c as ToAttach)) {
                  setError(c as keyof FormFields, {
                    message: e.title,
                    type: "manual",
                  });
                  handled = true;
                }
              });
            }
          }
          if (!handled) {
            setUnknownErrorNotification();
            throw error;
          }
        }
      }
    },
  );

  const submit = () => {
    void trigger().then((isValid) => {
      if (isValid) {
        if (user) {
          void sendRequest();
        } else {
          setShowWarning("full");
        }
      }
    });
  };

  return (
    <Dialog
      open
      onClose={() => setQuit(true)}
      title="Enrollment inquiry"
      data-test="enrollment-inquiry-response-dialog"
      tier={facility.subscriptionTier}
    >
      <div css={css.container}>
        {quit && (
          <QuitDialog
            onQuit={onClose}
            onClose={() => setQuit(false)}
            tier={facility.subscriptionTier}
          />
        )}

        {showWarning && (
          <SignInWarning
            onClose={() => setShowWarning(false)}
            variant={showWarning}
            onYes={() => {
              if (!facility.accountId) {
                const text = watch("response");
                const inquiryId = must(inquiry.id);
                setAfterClaimCallback(
                  async () => {
                    await FACILITY_API.facilityContactRequestsByIdRespond({
                      facilityContactRequestResponse: {
                        text,
                      },
                      id: inquiryId,
                    });

                    trackEvent({
                      action: "Submitted Successfully",
                      inquiryId,
                    });
                    void queryClient.invalidateQueries(QUERIES.USER_CHATS);
                  },
                  inquiryId,
                  text,
                );

                history.push(`/map/${facility.id}/claim`);
              } else {
                setShowWarning(false);
                checkAuthorization();
              }
            }}
            onNo={() => {
              setShowWarning(false);
              checkAuthorization();
            }}
          />
        )}

        {showUserError && <UserError onClose={() => setShowUserError(false)} />}

        {step === 2 && (
          <SuccessStep
            text="Response sent"
            content={
              <Fragment>
                <ShadowDivider />
                <div css={css.doneAction}>
                  <Button
                    variant="contained"
                    color="primary"
                    name="done"
                    size="large"
                    onClick={onClose}
                  >
                    Done
                  </Button>
                </div>
              </Fragment>
            }
          />
        )}

        {step === 1 && (
          <Fragment>
            <Typography bolded align="center" gutterBottom>
              {facility.name}
            </Typography>
            <Typography align="center" gap>
              {getFacilityFullAddress(facility)}
            </Typography>

            <div css={css.commentHeader}>
              <Typography gutterBottom bolded>
                {trancateName(inquiry.parentFirstName, inquiry.parentLastName)}
              </Typography>
              <Typography gutterBottom color="textSecondary" variant="body2">
                {!isNil(inquiry.createdAt) &&
                  DateTime.fromISO(inquiry.createdAt).toFormat("MM/dd/yyyy")}
              </Typography>
            </div>
            <Typography paragraph>{inquiry.comment}</Typography>

            <Spacer size="small" />
            <Button
              link={{
                hash: "#familyProfile=true",
                search,
              }}
              variant="outlined"
              color="black"
              css={css.viewProfileButton}
              name="familyProfile"
            >
              Family profile
            </Button>

            {!!inquiry.responses?.length && <Spacer size="medium" />}

            {qs.parse(hash).familyProfile && (
              <FamilyProfileDialog inquiry={inquiry} />
            )}

            {inquiry.responses?.map((r, i) => (
              <React.Fragment key={`inquiry-${i}`}>
                <div css={css.commentHeader}>
                  <Typography gutterBottom bolded>
                    {inquiry.parentUserId === r.userId
                      ? trancateName(
                          inquiry.parentFirstName,
                          inquiry.parentLastName,
                        )
                      : facility.name?.ellipsis(30)}
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    variant="body2"
                  >
                    {!isNil(r.createdAt) &&
                      DateTime.fromISO(r.createdAt).toFormat("MM/dd/yyyy")}
                  </Typography>
                </div>
                <Typography gap>{r.text}</Typography>
              </React.Fragment>
            ))}

            <ShadowDivider />

            {user?.id === inquiry.parentUserId && (
              <Fragment>
                <div
                  css={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent:
                      facility.subscriptionTier ===
                      Models.SubscriptionTier.Community
                        ? "center"
                        : "space-between",
                  }}
                >
                  <Button
                    href={`/map/${facility.id}/photos`}
                    target="_blank"
                    variant="outlined"
                    color="black"
                    css={css.viewProfileButton}
                    name="viewPhotoGallery"
                  >
                    View photo gallery
                  </Button>
                  <Spacer size="small" />

                  {facility.subscriptionTier !==
                    Models.SubscriptionTier.Community && (
                    <Button
                      href={`/map/${facility.id}/virtual-tour`}
                      target="_blank"
                      variant="outlined"
                      color="black"
                      css={css.viewProfileButton}
                      name="virtualSchoolTour"
                    >
                      Virtual school tour
                    </Button>
                  )}
                </div>
                <Spacer size="small" />
              </Fragment>
            )}

            <Controller
              as={
                <OutlinedInput
                  multiline
                  placeholder="Enter response here"
                  rowsMax="6"
                  data-test="response-field"
                  maxChars={750}
                  error={errors.response?.message}
                />
              }
              name="response"
              defaultValue={responseText || ""}
              control={control}
              rules={required()}
            />

            {renderOwnerFields()}

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={submit}
              fullWidth
              name="respondInquiry"
              css={css.sendButton}
              loading={formState.isSubmitting}
              disabled={formState.isSubmitSuccessful}
            >
              Respond
            </Button>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default track({
  page: "Inquiry Response",
})(ServiceInquiryResponse);
