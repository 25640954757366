import React, { useEffect } from "react";
import { useTracking } from "react-tracking";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

interface SignInWarningProps {
  onClose: () => void;
  onYes: () => void;
  onNo: () => void;
  variant: "full" | "administrator";
}

export const SignInWarning: React.FC<SignInWarningProps> = ({
  onClose,
  onYes,
  onNo,
  variant,
}) => {
  const { trackEvent } = useTracking();
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  useEffect(() => {
    trackEvent({
      action: "Show Choose User Type Dialog",
    });
  }, []);

  return (
    <Dialog
      open
      title="Inquiry response"
      onClose={onClose}
      tier={facility.subscriptionTier}
    >
      <div css={css.container}>
        {variant === "full" && (
          <Typography paragraph>Are you the school administrator?</Typography>
        )}
        {variant === "administrator" && (
          <Typography paragraph>
            Only school administrator can respond
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          css={css.button}
          onClick={onYes}
          name="thisIsMySchool"
        >
          Yes, this is my school
        </Button>
        {variant === "full" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onNo}
            fullWidth
            size="large"
            css={css.button}
            name="iAmAParent"
          >
            No, I am a parent
          </Button>
        )}
      </div>
    </Dialog>
  );
};
