import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    minHeight: 100,
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  commentHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },

  sendButton: {
    marginTop: 30,
  },

  doneAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },
  },

  linkRow: {
    alignItems: "flex-start",
    display: "flex",

    "& .checkbox": {
      marginRight: 15,
      marginTop: 40,
      padding: 0,
    },
  },

  viewProfileButton: {
    display: "flex",
    margin: "0 auto",
  },
});
